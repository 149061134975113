import React from 'react';
import sleepyrat from '../assets/sleepyrat.png';
import { LinkContainer } from 'react-router-bootstrap';


const publications = [
    {
      "url": "https://www.nature.com/articles/s41551-023-01029-x",
      "title": "A wireless and battery-less implant for multimodal closed-loop neuromodulation in small animals",
      "authors": "W Ouyang, W Lu, Y Zhang, Y Liu, JU Kim… - Nature Biomedical …, 2023",
      "year": "2023"
    },
    {
      "url": "https://www.cell.com/neuron/fulltext/S0896-6273(20)30608-5",
      "title": "Circadian VIPergic neurons of the suprachiasmatic nuclei sculpt the sleep-wake cycle",
      "authors": "B Collins, S Pierre-Ferrer, C Muheim, D Lukacsovich… - Neuron, 2020",
      "year": "2020"
    },
    {
      "url": "https://www.cell.com/neuron/fulltext/S0896-6273(23)00758-4?",
      "title": "Dopamine pathways mediating affective state transitions after sleep loss",
      "authors": "M Wu, X Zhang, S Feng, SN Freda, P Kumari… - Neuron, 2024",
      "year": "2024"
    },
    {
      "url": "https://onlinelibrary.wiley.com/doi/abs/10.1111/ejn.15884",
      "title": "Sleep scoring in rodents: Criteria, automatic approaches and outstanding issues",
      "authors": "A Rayan, A Agarwal, A Samanta… - European Journal of …, 2024",
      "year": "2024"
    },
    {
      "url": "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0224642",
      "title": "Robust, automated sleep scoring by a compact neural network with distributional shift correction",
      "authors": "Z Barger, CG Frye, D Liu, Y Dan, KE Bouchard - PloS one, 2019",
      "year": "2019"
    },
    {
      "url": "https://www.nature.com/articles/s41598-019-51269-8",
      "title": "MC-SleepNet: large-scale sleep stage scoring in mice by deep neural networks",
      "authors": "M Yamabe, K Horie, H Shiokawa, H Funato… - Scientific reports, 2019",
      "year": "2019"
    },
    {
      "url": "https://www.mdpi.com/2079-9292/12/5/1098",
      "title": "New Perspectives in Nonintrusive Sleep Monitoring for Neurodegenerative Diseases—A Narrative Review",
      "authors": "G Masi, G Amprimo, L Priano, C Ferraris - Electronics, 2023",
      "year": "2023"
    },
    {
      "url": "https://content.iospress.com/articles/journal-of-alzheimers-disease/jad201163",
      "title": "Quantitative assessment of resting-state for mild cognitive impairment detection: a functional near-infrared spectroscopy and deep learning approach",
      "authors": "D Yang, KS Hong - Journal of Alzheimer's Disease, 2021",
      "year": "2021"
    },
    {
      "url": "https://elifesciences.org/articles/81892",
      "title": "Antisense oligonucleotide therapy rescues disturbed brain rhythms and sleep in juvenile and adult mouse models of Angelman syndrome",
      "authors": "D Lee, W Chen, HN Kaku, X Zhuo, ES Chao, A Soriano… - Elife, 2023",
      "year": "2023"
    },
    {
      "url": "https://academic.oup.com/sleep/article-abstract/45/2/zsab260/6414386",
      "title": "High-throughput visual assessment of sleep stages in mice using machine learning",
      "authors": "B Geuther, M Chen, RJ Galante, O Han, J Lian… - Sleep, 2022",
      "year": "2022"
    },
    {
        "url": "https://www.nature.com/articles/s41598-021-91286-0",
        "title": "Automated scoring of pre-REM sleep in mice with deep learning",
        "authors": "N Grieger, JTC Schwabedal, S Wendel, Y Ritze… - Scientific Reports, 2021",
        "year": "2021"
      },
      {
        "url": "https://www.nature.com/articles/s41598-023-31288-2",
        "title": "IntelliSleepScorer, a software package with a graphic user interface for automated sleep stage scoring in mice based on a light gradient boosting machine algorithm",
        "authors": "LA Wang, R Kern, E Yu, S Choi, JQ Pan - Scientific Reports, 2023",
        "year": "2023"
      },
      {
        "url": "https://journals.biologists.com/dmm/article/17/6/dmm050379/352631",
        "title": "Purkinje cell dysfunction causes disrupted sleep in ataxic mice",
        "authors": "LE Salazar Leon, AM Brown, H Kaku… - Disease Models & …, 2024",
        "year": "2024"
      },
      {
        "url": "https://www.mdpi.com/2076-3921/12/5/1124",
        "title": "Effect of N-acetylcysteine on sleep: Impacts of sex and time of day",
        "authors": "PN Bushana, MA Schmidt, KM Chang, T Vuong… - Antioxidants, 2023",
        "year": "2023"
      },
      {
        "url": "https://www.frontierspartnerships.org/articles/10.3389/dyst.2023.11487/full",
        "title": "Disrupted sleep in dystonia depends on cerebellar function but not motor symptoms in mice",
        "authors": "LE Salazar Leon, RV Sillitoe - Dystonia, 2023",
        "year": "2023"
      },
      {
        "url": "https://ieeexplore.ieee.org/abstract/document/9799010/",
        "title": "Application of machine learning to sleep stage classification",
        "authors": "A Smith, H Anand, S Milosavljevic… - 2021 International …, 2021",
        "year": "2021"
      },
      {
        "url": "https://dl.acm.org/doi/abs/10.1145/3366174.3366186",
        "title": "Nr-gan: Noise reduction gan for mice electroencephalogram signals",
        "authors": "Y Sumiya, K Horie, H Shiokawa… - Proceedings of the 2019 …, 2019",
        "year": "2019"
      },
      {
        "url": "https://www.cell.com/cell-reports/fulltext/S2211-1247(24)00829-5?fbclid=IwZXh0bgNhZW0CMTEAAR1EYTOMmLTzoevcT-jFrvqK3Lm3TF1f_uzmVzxgGjoB6pYm-bX3OBwqA1c_aem_gBILlHWqj9-9DzVli6Qjcg",
        "title": "BDNF-TrkB signaling orchestrates the buildup process of local sleep",
        "authors": "W ElGrawani, G Sun, FP Kliem, S Sennhauser… - Cell reports, 2024",
        "year": "2024"
      },
      {
        "url": "https://proceedings.neurips.cc/paper_files/paper/2022/hash/3ed57b293db0aab7cc30c44f45262348-Abstract-Conference.html",
        "title": "Learning to drop out: An adversarial approach to training sequence vaes",
        "authors": "D Miladinovic, K Shridhar, K Jain… - Advances in …, 2022",
        "year": "2022"
      },
      {
        "url": "https://www.cell.com/cell-reports/fulltext/S2211-1247(24)00520-5?uuid=uuid%3Ae771cc71-3353-4a7b-ba4b-30e08af5b09c",
        "title": "Single-nucleus RNA-seq identifies one galanin neuronal subtype in mouse preoptic hypothalamus activated during recovery from sleep deprivation",
        "authors": "X Guo, BT Keenan, BC Reiner, J Lian, AI Pack - Cell Reports, 2024",
        "year": "2024"
      },
      {
        "url": "https://ieeexplore.ieee.org/abstract/document/10224751/",
        "title": "SDREAMER: Self-distilled mixture-of-modality-experts transformer for automatic sleep staging",
        "authors": "J Chen, Y Yao, M Anderson… - … on Digital Health …, 2023",
        "year": "2023"
      },
      {
        "url": "https://elifesciences.org/articles/68043",
        "title": "Closed-loop auditory stimulation method to modulate sleep slow waves and motor learning performance in rats",
        "authors": "CG Moreira, CR Baumann, M Scandella… - Elife, 2021",
        "year": "2021"
      },
      {
        "url": "https://academic.oup.com/sleep/article-abstract/46/6/zsad101/7109541",
        "title": "A novel machine learning system for identifying sleep–wake states in mice",
        "authors": "JJ Fraigne, J Wang, H Lee, R Luke, SK Pintwala… - Sleep, 2023",
        "year": "2023"
      },
      {
        "url": "https://www.sciencedirect.com/science/article/pii/S0168010221001735",
        "title": "A deep learning algorithm for sleep stage scoring in mice based on a multimodal network with fine-tuning technique",
        "authors": "K Akada, T Yagi, Y Miura, CT Beuckmann… - Neuroscience …, 2021",
        "year": "2021"
      },
      {
        "url": "https://www.nature.com/articles/s41598-024-54727-0",
        "title": "SlumberNet: deep learning classification of sleep stages using residual neural networks",
        "authors": "PK Jha, UK Valekunja, AB Reddy - Scientific Reports, 2024",
        "year": "2024"
      },
      {
        "url": "https://www.spandidos-publications.com/10.3892/etm.2023.11932",
        "title": "Establishment of a chronic insomnia rat model of sleep fragmentation using unstable platforms surrounded by water",
        "authors": "DQ Yan, XP Zhang, WH Zhang… - Experimental and …, 2023 - spandidos",
        "year": "2023"
      },
      {
        "url": "https://onlinelibrary.wiley.com/doi/abs/10.1111/jsr.13615",
        "title": "Down‐phase auditory stimulation is not able to counteract pharmacologically or physiologically increased sleep depth in traumatic brain injury rats",
        "authors": "CG Moreira, P Hofmann, A Müllner… - Journal of Sleep …, 2022",
        "year": "2022"
      },
      {
        "url": "https://www.sciencedirect.com/science/article/pii/S0165027024001006",
        "title": "Workflow for the unsupervised clustering of sleep stages identifies light and deep sleep in electrophysiological recordings in mice",
        "authors": "R Cusinato, S Gross, M Bainier, P Janz… - Journal of Neuroscience …, 2024",
        "year": "2024"
      },
      {
        "url": "https://iopscience.iop.org/article/10.1088/1361-6579/ac7b67/meta",
        "title": "An attention-based temporal convolutional network for rodent sleep stage classification across species, mutants and experimental environments with single-channel …",
        "authors": "Y Liu, Z Yang, Y You, W Shan… - Physiological …, 2022",
        "year": "2022"
      },
      {
        "url": "https://www.jstage.jst.go.jp/article/kjm/72/2/72_2022-0020-OA/_article/-char/ja/",
        "title": "Pupil dynamics-derived sleep stage classification of a head-fixed mouse using a recurrent neural network",
        "authors": "G Kobayashi, KF Tanaka, N Takata - The Keio Journal of Medicine, 2023",
        "year": "2023"
      },
      {
        "url": "https://peerj.com/articles/12127/",
        "title": "An artificial neural network for automated behavioral state classification in rats",
        "authors": "JG Ellen, MB Dash - PeerJ, 2021",
        "year": "2021"
      },
      {
        "url": "https://www.biorxiv.org/content/10.1101/2023.02.09.527916.abstract",
        "title": "Disrupted sleep in dystonia depends on cerebellar function but not motor symptoms in mice",
        "authors": "LES Leon, RV Sillitoe - BioRxiv, 2023",
        "year": "2023"
      },
      {
        "url": "https://onlinelibrary.wiley.com/doi/abs/10.1111/ejn.16465",
        "title": "An automated sleep staging tool based on simple statistical features of mice electroencephalography (EEG) and electromyography (EMG) data",
        "authors": "RG Yamada, K Matsuzawa, KL Ode… - European Journal of …, 2024",
        "year": "2024"
      },
      {
        "url": "https://www.frontiersin.org/articles/10.3389/fnbeh.2021.799271/full",
        "title": "Sleep-Wake Rhythm and Oscillatory Pattern Analysis in a Multiple Hit Schizophrenia Rat Model (Wisket)",
        "authors": "LG Adlan, M Csordás-Nagy, B Bodosi… - Frontiers in Behavioral …, 2022",
        "year": "2022"
      },
      {
        "url": "https://onlinelibrary.wiley.com/doi/abs/10.1111/jsr.13262",
        "title": "A real‐time sleep scoring framework for closed‐loop sleep manipulation in mice",
        "authors": "D Huffman, A Ajwad, F Yaghouby… - Journal of Sleep …, 2021",
        "year": "2021"
      },
      {
        "url": "https://www.sciencedirect.com/science/article/pii/S016502702100159X",
        "title": "WaveSleepNet: An interpretable deep convolutional neural network for the continuous classification of mouse sleep and wake",
        "authors": "K Kam, DM Rapoport, A Parekh, I Ayappa… - Journal of neuroscience …, 2021",
        "year": "2021"
      },
      {
        "url": "https://onlinelibrary.wiley.com/doi/abs/10.1111/jsr.13233",
        "title": "Identification of the sleep–wake states in rats using the high‐frequency activity of the electroencephalogram",
        "authors": "M Silva‐Pérez, A Sánchez‐López… - Journal of Sleep …, 2021",
        "year": "2021"
      },
      {
        "url": "https://cyberleninka.ru/article/n/vliyanie-klozapina-i-5-nt2a-antagonista-ru-31-na-elektroentsefalogrammu-i-dvigatelnuyu-aktivnost-krys-v-modeli-shizofrenii-s",
        "title": "Влияние клозапина и 5-НТ2А-антагониста РУ-31 на электроэнцефалограмму и двигательную активность крыс в модели шизофрении с …",
        "authors": "КЮ Калитин, ГВ Придворов, АА Спасов… - Кубанский научный …, 2022",
        "year": "2022"
      },
      {
        "url": "https://academic.oup.com/sleepadvances/advance-article/doi/10.1093/sleepadvances/zpae022/7640723",
        "title": "Sleep-Deep-Learner is taught sleep–wake scoring by the end-user to complete each record in their style",
        "authors": "F Katsuki, TJ Spratt, RE Brown, R Basheer… - Sleep …, 2024",
        "year": "2024"
      },
      {
        "url": "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10769368/",
        "title": "Sleep-Deep-Net learns sleep wake scoring from the end-user and completes each record in their style",
        "authors": "F Katsuki, TJ Spratt, RE Brown, R Basheer, DS Uygun - bioRxiv, 2023",
        "year": "2023"
      },
      {
        "url": "https://www.biorxiv.org/content/10.1101/2024.02.12.579922.abstract",
        "title": "Novel murine closed-loop auditory stimulation paradigm elicits macrostructural sleep benefits in neurodegeneration",
        "authors": "I Dias, S Kollarik, M Siegel, CR Baumann, CG Moreira… - bioRxiv, 2024",
        "year": "2024"
      },
      {
        "url": "https://www.biorxiv.org/content/10.1101/2023.10.30.564790.abstract",
        "title": "Cerebellar deep brain stimulation as a dual function therapeutic for restoring movement and sleep in dystonic mice",
        "authors": "LES Leon, LH Kim, RV Sillitoe - bioRxiv, 2023",
        "year": "2023"
      },
      {
        "url": "https://arxiv.org/abs/2406.16911",
        "title": "Evaluating the Influence of Temporal Context on Automatic Mouse Sleep Staging through the Application of Human Models",
        "authors": "JG Ciudad, M Mørup, BR Kornum, AN Zahid - arXiv preprint arXiv …, 2024",
        "year": "2024"
      },
      {
        "url": "https://www.biorxiv.org/content/10.1101/2020.07.06.186940.abstract",
        "title": "Sleep identification enabled by supervised training algorithms (SIESTA): an open-source platform for automatic sleep staging of rodent polysomnographic data",
        "authors": "CS Caldart, REA Sanchez, M Ben-Hamo, AI Beck… - BioRxiv, 2020",
        "year": "2020"
      },
      {
        "url": "https://academic.oup.com/sleepadvances/article-abstract/4/1/zpad044/7484434",
        "title": "Chronic dietary supplementation with nicotinamide riboside reduces sleep need in the laboratory mouse",
        "authors": "PN Bushana, MA Schmidt, MJ Rempe, BA Sorg… - Sleep …, 2023",
        "year": "2023"
      },
      {
        "url": "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9579869/",
        "title": "Validation of Deep Learning-based Sleep State Classification",
        "authors": "W Chen, X Zhang, H Miao, MJ Tang… - microPublication …, 2022",
        "year": "2022"
      },
      {
        "url": "https://www.biorxiv.org/content/10.1101/2021.10.25.465747.abstract",
        "title": "Natural age-related sleep-wake alterations onset prematurely in the Tg2576 mouse model of Alzheimer's disease",
        "authors": "S Kollarik, CG Moreira, I Dias, D Bimbiryte… - bioRxiv, 2021",
        "year": "2021"
      },
      {
        "url": "https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1011793",
        "title": "Somnotate: A probabilistic sleep stage classifier for studying vigilance state transitions",
        "authors": "PJN Brodersen, H Alfonsa, LB Krone… - PLOS Computational …, 2024",
        "year": "2024"
      },
      {
        "url": "https://joss.theoj.org/papers/10.21105/joss.02377.pdf",
        "title": "Automated Sleep Stage Scoring Using* k*-Nearest Neighbors Classifier",
        "authors": "T Kiss, S Morairty, M Schwartz, S Thomas… - Journal of Open …, 2020",
        "year": "2020"
      },
      {
        "url": "https://www.research-collection.ethz.ch/handle/20.500.11850/657089",
        "title": "The Role of Posttranscriptional Gene Regulation During Homeostatic Synaptic Plasticity: Neuroscience",
        "authors": "D Colameo - 2023 - research",
        "year": "2023"
      },
      {
        "url": "https://search.proquest.com/openview/581031dca1f8325f0839aadc479e6ab6/1?pq-origsite=gscholar&cbl=18750&diss=y",
        "title": "Sleep is important: How circadian-timed trauma keeps mice up at day and the societal implications that keep me up at night",
        "authors": "AI Beck - 2023",
        "year": "2023"
      },
      {
        "url": "https://ieeexplore.ieee.org/abstract/document/9581203/",
        "title": "Exploring Relationships between Cerebral and Peripheral Biosignals with Neural Networks",
        "authors": "AH Hatteland, R Marcinkevičs… - … on Digital Health …, 2021",
        "year": "2021"
      },
      {
        "url": "https://ieeexplore.ieee.org/abstract/document/10055401/",
        "title": "Co-Loss Function for Rodents Sleep Stage Scoring Based on Single-Channel EEG",
        "authors": "S Chang, Y You - 2022 China Automation Congress (CAC), 2022",
        "year": "2022"
      },
      {
        "url": "https://search.proquest.com/openview/757ca888aabfa940acb9b1baee74de3d/1?pq-origsite=gscholar&cbl=18750&diss=y",
        "title": "Dopaminergic Modulation of Affective State and Behavior",
        "authors": "M Wu - 2022",
        "year": "2022"
      },
      {
        "url": "https://www.research-collection.ethz.ch/handle/20.500.11850/504944",
        "title": "Closed-loop auditory modulation of slow waves: development and application in healthy and traumatic brain injury rats",
        "authors": "C Gonçalves Moreira - 2021 - research",
        "year": "2021"
      },
      {
        "url": "https://ksma.elpub.ru/jour/article/view/3206?locale=en_US",
        "title": "Postmortem tissue changes and dynamics of their impedance parameters: a preclinical experimental study",
        "authors": "OS Lavrukova, EL Kazakova… - Kuban Scientific Medical …, 2023",
        "year": "2023"
      },
      {
        "url": "https://ksma.elpub.ru/jour/article/view/3206",
        "title": "Посмертные изменения тканей и динамика их импедансометрических показателей: доклиническое экспериментальное исследование",
        "authors": "ОС Лаврукова, ЕЛ Казакова… - Кубанский научный …, 2023",
        "year": "2023"
      },
      {
        "url": "https://www.researchgate.net/profile/Anik-Muhury/publication/357517711_Machine_Learning_Approach_for_Vigilance_State_Classification_in_Mice/links/61d231d2b8305f7c4b1c38dc/Machine-Learning-Approach-for-Vigilance-State-Classification-in-Mice.pdf",
        "title": "Machine Learning Approach for Vigilance State Classification in Mice",
        "authors": "A Muhury - 2021",
        "year": "2021"
      },
      {
        "url": "https://thesis.unipd.it/retrieve/59de38df-bbfc-4a0e-936a-bde933753fda/Cusinato_Riccardo.pdf",
        "title": "Machine Learning approaches in Neuroscience: behavioral and sleep classification",
        "authors": "R Cusinato",
        "year": "2021"
      },
      {
        "url": "https://search.proquest.com/openview/9f8f3c0b31bcf7d8aa81934df0bbeffb/1?pq-origsite=gscholar&cbl=51922&diss=y",
        "title": "Mechanisms of Sleep-Dependent Memory Consolidation in the Rodent Prefrontal Cortex",
        "authors": "BH Harper - 2020",
        "year": "2020"
      },
      {
        "url": "https://search.proquest.com/openview/3a9deef880c2838c563f2461faf0a8af/1?pq-origsite=gscholar&cbl=18750&diss=y",
        "title": "Sleeping on the Job: How studying sleep and circadian rhythm disturbances in a mouse model of Dravet syndrome kept me up at night",
        "authors": "R Sanchez - 2020",
        "year": "2020"
      },
      {
        "url": "https://scholar.archive.org/work/5jzhatlrnjfvvmavjy6wyhawem/access/wayback/https://escholarship.org/content/qt2jt7v8pd/qt2jt7v8pd.pdf?t=qhtt6o",
        "title": "Robust, automated sleep scoring by a compact neural network with distributional shift",
        "authors": "Z Barger - 2020",
        "year": "2020"
      }
  ]



interface ReferenceProps {
    url: string;
    title: string;
    authors: string;
    year: string;
  }
  
  const Reference: React.FC<ReferenceProps> = ({ url, title, authors, year }) => (
    <div className="references-item">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="references-item-link"
      >
        <div className="content">
          <span>
            {title} | <em className="text_18 ff-title" style={{ letterSpacing: '0.4px' }}>{authors}</em>
          </span>
          <span className="date sleepyrat_blue">{year}</span>
        </div>
      </a>
    </div>
  );
  



const SleepLearning: React.FC = () => {
    return (
        <div>
            <div style={{backgroundColor: '#000000'}}>
                <div  className='limitmaxwidth'>
                    <img src={sleepyrat} alt="A sleepy rat, symbolizing the study of animal sleep patterns" width="100%" height="100%" style={{margin: 'auto', display: 'block'}}/>
                </div>
            </div>
            <div className="container limitmaxwidth">
                <div className="row justify-content-center">
                    <div className="col-12 py-4 justify-content-center text-center" style={{marginBottom: 200, }}>
                        <h2>About</h2>
                        <p className="text-justify" style={{textAlign:"left"}}>
Welcome to the homepage of the Sleepyrat, a web platform which implements an animal sleep scoring algorithm that is popular amongst research labs.<br/><br/>

You can use the platform free of charge by simply <LinkContainer to="/signup"><a>signing up</a></LinkContainer> with your email address.<br/><br/>

Our program takes as input EEG/EMG signals recorded over a certain period of time, e.g. 8-24 hours, and automatically evaluates their sleep dynamics by segmenting EEG/EMGs into 4 sec epochs which are then categorized into either:<br/>
(a) One of the three basic vigilance states: wakefulness, NREM, REM<br/>
(b) One of three types of artifacts: wake-artifact, NREM-artifact, REM-artifact.<br/><br/>

Our machine learning-based method is designed to provide fast, accurate and parameter-free evaluation of animal sleep recordings.<br/>

{/* More information can be found "here"[Link to FAQ] */}
More information can be found <LinkContainer to="/paper-and-dataset"><a>here</a></LinkContainer>.<br/><br/>
                        </p>
                        <h2>Publications that cite our work</h2>

                        <div className="references_overview" style={{
                            height: '685px',
                            overflowY: 'auto',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            padding: '10px'
                        }}>
                            <div className="references-filter-view">
                                {publications.map((publication, index) => (
                                    <Reference
                                        key={index}
                                        url={publication.url}
                                        title={publication.title}
                                        authors={publication.authors}
                                        year={publication.year}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SleepLearning;





// extraction code for https://scholar.google.com/scholar?start=0&hl=en&as_sdt=2005&sciodt=0,5&cites=2280435582553245478&scipsc=

// let papers = [];

// document.querySelectorAll('#gs_res_ccl_mid .gs_r').forEach(paper => {
//   let titleElement = paper.querySelector('.gs_rt a');
//   let authorYearElement = paper.querySelector('.gs_a');

//   if (titleElement && authorYearElement) {
//     let url = titleElement.href;
//     let title = titleElement.textContent;
//     let authorYearText = authorYearElement.textContent;
    
//     // Extract authors (everything before the last dash)
//     let authors = authorYearText.substring(0, authorYearText.lastIndexOf('-')).trim();
    
//     // Extract year (last 4 digits in the string)
//     let yearMatch = authorYearText.match(/\b\d{4}\b/);
//     let year = yearMatch ? yearMatch[0] : null;

//     papers.push({
//       url,
//       title,
//       authors,
//       year
//     });
//   }
// });

// console.log(JSON.stringify(papers, null, 2));