import React, { useContext } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { UserContext } from '../UserContext';

const NavigationBar: React.FC = () => {

  return (
    <footer className='sleepyrat_mediumdark no_link_decoration' style={{position: 'fixed', bottom: 0, width: '100%'}}>


{/* <div className="footercontainer"> © SleepyRat </div> */}
<LinkContainer to="/" className="footercontainer"><Nav.Link>© SleepyRat</Nav.Link></LinkContainer>
{/* <LinkContainer to="/about" className="footercontainer"><Nav.Link>About Us</Nav.Link></LinkContainer> */}
<LinkContainer to="/terms" className="footercontainer"><Nav.Link>Terms</Nav.Link></LinkContainer>
<div className="footercontainer"> <a href="mailto:support@sleepyrat.ai">Contact</a> </div>
<LinkContainer to="/paper-and-dataset" className="footercontainer"><Nav.Link>FAQ</Nav.Link></LinkContainer>
    </footer>
  );
};

export default NavigationBar;