import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import SleepLearning from './pages/SleepLearning';
import Projects from './pages/Projects';
import Faq from './pages/Faq';
import Profile from './pages/Profile';
import Testing from './pages/Testing';
import FolderDetails from './pages/FolderDetails';
import Signup from './pages/Signup';
import Login from './pages/Login';
import About from './pages/About';
import Terms from './pages/Terms';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import { UserProvider } from './UserContext';
import { UserContext } from './UserContext';
import config from './config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {

  const { user, setUser, isLoading, setIsLoading } = useContext(UserContext);

  useEffect(() => {
    document.title = "SleepyRat"
    const validateToken = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch(`${config.API_BASE_URL}/validate_token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUser({ username: data.username });
          } else {
            console.error('Token validation failed');
            // Clear user data on validation failure
            localStorage.removeItem('token');
            setUser(null);
          }
        } catch (error) {
          console.error('Error during token validation:', error);
          localStorage.removeItem('token');
          setUser(null);
        }
      } else {
        // If there's no token, do not set the user, implying they are not logged in
        setUser(null);
      }
      setIsLoading(false);
    };

    validateToken();
  }, [setUser, setIsLoading]);

  return (
    <Router>
      <NavigationBar />
      <Routes>
        {user || isLoading ? (
          // Routes available to authenticated users
          <>
            <Route path="/" element={<SleepLearning />} />
            <Route path="/projects" element={<Projects />} />
            {/* <Route path="/projects/:projectId" element={<ProjectDetails />} /> */}
            <Route path="/projects/:projectId/:folderId" element={<FolderDetails />} />
            <Route path="/paper-and-dataset" element={<Faq />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/testing" element={<Testing />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
          </>
        ) : (
          <>
            {/* Routes available to unauthenticated users */}
            <Route path="/" element={<SleepLearning />} />
            <Route path="/paper-and-dataset" element={<Faq />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            {/* Redirect to login page if user tries to access a protected route */}
            <Route path="/projects" element={<Login />} />
            <Route path="/projects/:projectId" element={<Login />} />
            <Route path="/projects/:projectId/:folderId" element={<Login />} />
            <Route path="/profile" element={<Login />} />
            <Route path="/testing" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </>
        )}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;